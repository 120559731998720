import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./Container"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "PNOĒ is the most vital assessment for optimizing your performance.",
  descriptions: [
    "If you haven't taken the PNOĒ test, you are training at least 50% less effectively for your next event.",
  ],
  button: {
    name: "Book your test now",
    to: "/payment",
  },
}

export default function Hero() {
  return (
    <Container bleed="right">
      <Container.Absolute>
        <Container.Title width="50rem">{DATA.title}</Container.Title>
        <Container.Description width="40rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <Button as={Link} to={DATA.button.to}>
          {DATA.button.name}
        </Button>
      </Container.Absolute>
      <Container.Image align="right">
        <StaticImage
          src="../../assets/images/why-get-tested/hero.jpg"
          alt="bicycle exercise"
          width={846}
          placeholder="blurred"
        />
      </Container.Image>
    </Container>
  )
}

Hero.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="50rem">{DATA.title}</Container.Title>
    <Container.Description width="40rem">
      {DATA.descriptions[0]}
    </Container.Description>
    <StaticImage
      src="../../assets/images/why-get-tested/hero-mobile.png"
      alt="bicycle exercise"
      width={846}
      placeholder="blurred"
    />
    <Button as={Link} to={DATA.button.to} mt={24}>
      {DATA.button.name}
    </Button>
  </Container>
)
